export type FooterLayout = 'withSidebar' | 'centerProse' | 'centerCheckout' | 'floating';

/**
 * Stores state of the footer
 * TODO: consider pinia
 */
export default function useFooterState() {
    const isEnabled = useState<boolean>('footer_is_enabled', () => true);
    const forwardCallback = useState<(() => any) | undefined>('footer_forward_callback', () => undefined);
    const forwardLabel = useState<string>('footer_forward_label', () => '');
    const backwardCallback = useState<(() => any) | undefined>('footer_backward_callback', () => undefined);
    const backwardLabel = useState<string>('footer_backward_label', () => '');
    const layout = useState<FooterLayout>('footer_layout', () => 'withSidebar');
    const isPriceVisible = useState<boolean>('footer_is_price_visible', () => true);
    const canProceed = useState<boolean>('footer_can_proceed', () => false);
    const canGoBack = useState<boolean>('footer_can_go_back', () => true);
    const isProceedDisabledVisually = useState<boolean>('footer_is_proceed_disabled_visually', () => false);
    const isProceedPending = useState<boolean>('footer_is_proceed_pending', () => false);
    const timeoutShowProceedPending = useState<NodeJS.Timeout | undefined>('footer_timeout_show_proceed_pending', () => undefined);
    const timeoutHideProceedPending = useState<NodeJS.Timeout | undefined>('footer_timeout_hide_proceed_pending', () => undefined);

    return {
        isEnabled,
        forwardLabel,
        forwardCallback,
        backwardCallback,
        backwardLabel,
        layout,
        isPriceVisible,
        canProceed,
        canGoBack,
        isProceedDisabledVisually,
        isProceedPending,
        timeoutShowProceedPending,
        timeoutHideProceedPending,
    };
}
